import {
    animate,
    group,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    signal,
} from '@angular/core';
import { AIActions } from '@modules/ai/store/ai.actions';
import { selectIsPanelAnimating } from '@modules/ai/store/ai.selectors';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'interacta-ai-panel-overlay',
    templateUrl: './ai-panel-overlay.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('panel', [
            transition(':enter', [
                group([
                    query(':self', [
                        style({ opacity: 0 }),
                        animate('300ms ease', style({ opacity: 1 })),
                    ]),
                ]),
            ]),
            transition(':leave', [
                group([
                    query(':self', [
                        animate('300ms ease', style({ opacity: 0 })),
                    ]),
                ]),
            ]),
        ]),
    ],
})
export class AIPanelOverlayComponent {
    private store = inject(Store);

    showContent = signal<boolean>(false);

    constructor() {
        this.store
            .select(selectIsPanelAnimating)
            .pipe(distinctUntilChanged(), takeUntilDestroyed())
            .subscribe((value) => this.showContent.set(!value));
    }

    close(): void {
        this.store.dispatch(AIActions.toggleAIPanel({ isOpen: false }));
    }
}
