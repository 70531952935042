<ng-container
    *ngIf="{
        activeThread: activeThread$ | async,
        sideThread: sideThread$ | async,
        activeThreadName: activeThreadName$ | async,
        activeContextType: activeContextType$ | async,
    } as $"
>
    <div class="size-full overflow-hidden">
        @if ($.activeContextType === AIContextType.CONTENT_GEN) {
            <section class="bg-surface-B h-full rounded-2xl px-16 py-20">
                <interacta-ai-panel-content-generation />
            </section>
        } @else if ($.activeContextType) {
            <section
                class="bg-surface-B h-full rounded-r-2xl rounded-bl-2xl px-16 py-20"
                [ngClass]="{
                    'rounded-tl-2xl':
                        $.activeContextType !== AIContextType.POST,
                }"
            >
                <div
                    class="relative z-0 size-full"
                    [ngClass]="{
                        'min-h-544 lg:min-h-fit':
                            $.activeContextType === AIContextType.POST,
                    }"
                >
                    <interacta-ai-panel-chat
                        class="bg-surface-B absolute size-full"
                        [aiPostAttachmentEnabled]="aiPostAttachmentEnabled"
                        [attr.aria-hidden]="$.activeThread !== MAIN_THREAD"
                        [attr.inert]="
                            $.activeThread !== MAIN_THREAD ? '' : null
                        "
                        [threadId]="MAIN_THREAD"
                    />
                    @if ($.sideThread) {
                        <interacta-ai-panel-chat
                            class="bg-surface-B absolute z-10 size-full transition-transform"
                            [attr.aria-hidden]="$.activeThread === MAIN_THREAD"
                            [attr.inert]="
                                $.activeThread === MAIN_THREAD ? '' : null
                            "
                            [ngClass]="{
                                'translate-x-0': $.activeThread !== MAIN_THREAD,
                                'translate-x-[110%]':
                                    $.activeThread === MAIN_THREAD,
                            }"
                            [style.transition-duration.ms]="ANIMATION_DURATION"
                            [threadId]="$.sideThread"
                            [threadName]="$.activeThreadName"
                        />
                    }
                </div>
            </section>
        }
    </div>
</ng-container>
