import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { PageTokenInfo } from '@interacta-shared/util';
import { ThemeMode } from '@interacta-shared/util-common';
import { AttachmentsListType } from '@modules/ai/models/ai.model';
import { AIActions } from '@modules/ai/store/ai.actions';
import {
    selectCachedAttachmentsCommentList,
    selectCachedAttachmentsCustomFieldsList,
    selectCachedAttachmentsPostList,
    selectCachedAttachmentsScreenFieldsList,
} from '@modules/ai/store/ai.selectors';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'interacta-ai-panel-chat-attachment-button',
    templateUrl: './ai-panel-chat-attachment-button.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIPanelChatAttachmentButtonComponent implements OnInit {
    @Input({ required: true }) theme!: ThemeMode;
    @Input({ required: true }) postId!: number;
    @Input({ required: true }) workflowEnabled!: boolean;

    isOpen = false;

    postAttachments$?: Observable<IListAttachments>;
    commentsAttachments$?: Observable<IListAttachments>;
    customFieldsAttachments$?: Observable<IListAttachments>;
    screenFieldsAttachments$?: Observable<IListAttachments>;

    selectedAttachment$ = new BehaviorSubject<{
        attachment: IAttachment;
        entityType: AttachmentsListType;
    } | null>(null);

    constructor(
        private store: Store,
        private translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.postAttachments$ = this.store.select(
            selectCachedAttachmentsPostList,
        );
        this.commentsAttachments$ = this.store.select(
            selectCachedAttachmentsCommentList,
        );

        this.customFieldsAttachments$ = this.store.select(
            selectCachedAttachmentsCustomFieldsList,
        );

        this.screenFieldsAttachments$ = this.store.select(
            selectCachedAttachmentsScreenFieldsList,
        );

        this.store.dispatch(
            AIActions.fetchAttachmentsList({
                postId: this.postId,
                entityType: 'post',
                pageToken: undefined,
                initial: true,
            }),
        );
        this.store.dispatch(
            AIActions.fetchAttachmentsList({
                postId: this.postId,
                entityType: 'comment',
                pageToken: undefined,
                initial: true,
            }),
        );
        this.store.dispatch(
            AIActions.fetchAttachmentsList({
                postId: this.postId,
                entityType: 'postFilePicker',
                pageToken: undefined,
                initial: true,
            }),
        );
        if (this.workflowEnabled)
            this.store.dispatch(
                AIActions.fetchAttachmentsList({
                    postId: this.postId,
                    entityType: 'screenFilePicker',
                    pageToken: undefined,
                    initial: true,
                }),
            );
    }

    loadMore(entityType: AttachmentsListType, pageToken: PageTokenInfo): void {
        if (pageToken.tag === 'lastLoading') return;

        this.store.dispatch(
            AIActions.fetchAttachmentsList({
                postId: this.postId,
                entityType,
                pageToken:
                    pageToken.tag === 'regularLoading'
                        ? pageToken.nextPageToken
                        : undefined,
            }),
        );
    }

    selectAttachment(
        entityType: AttachmentsListType | null,
        attachment: IAttachment | null,
    ): void {
        const currentValues = this.selectedAttachment$.getValue();
        if (
            currentValues?.attachment.id === attachment?.id &&
            currentValues?.entityType === entityType
        ) {
            this.selectedAttachment$.next(null);
        } else {
            this.selectedAttachment$.next(
                entityType && attachment ? { attachment, entityType } : null,
            );
        }
    }

    sendSelectedAttachmentToAI(): void {
        const attachment = this.selectedAttachment$.getValue()?.attachment;

        if (!attachment) return;

        this.store.dispatch(
            AIActions.addThreadInActiveContext({
                threadId: attachment.id,
                attachment,
                requestMessage: this.translateService.instant(
                    'AI.ATTACHMENT_REQUEST',
                ),
            }),
        );
        this.isOpen = false;
    }
}
