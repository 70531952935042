import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ANIMATION_DURATION_MS } from '../components/ai-fab/ai-fab.component';
import { AIPanelOverlayComponent } from '../components/ai-panel-overlay/ai-panel-overlay.component';
import { AIActions } from '../store/ai.actions';

@Injectable({
    providedIn: 'root',
})
export class AIOverlayService {
    private overlay = inject(Overlay);
    private store = inject(Store);

    private overlayRef: OverlayRef;

    readonly close$ = new Subject<void>();

    constructor() {
        this.overlayRef = this.overlay.create({
            hasBackdrop: false,
        });
    }

    open(): void {
        this.store.dispatch(
            AIActions.setIsPanelAnimating({ isAnimating: true }),
        );

        this.overlayRef.detach();

        const postPreviewPortal = new ComponentPortal(AIPanelOverlayComponent);

        this.overlayRef.attach(postPreviewPortal);

        setTimeout(() => {
            this.store.dispatch(
                AIActions.setIsPanelAnimating({ isAnimating: false }),
            );
        }, ANIMATION_DURATION_MS);
    }

    close(): void {
        this.store.dispatch(
            AIActions.setIsPanelAnimating({ isAnimating: true }),
        );

        this.overlayRef.detach();

        setTimeout(() => {
            this.store.dispatch(
                AIActions.setIsPanelAnimating({ isAnimating: false }),
            );
            this.close$.next();
        }, ANIMATION_DURATION_MS);
    }
}
