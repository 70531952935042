import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    signal,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AIActions } from '@modules/ai/store/ai.actions';
import { selectIsPanelOpen } from '@modules/ai/store/ai.selectors';
import { Store } from '@ngrx/store';
import { debounceTime, Observable, tap } from 'rxjs';

import { selectCurrentTheme, Theme } from '@interacta-shared/data-access-theme';
import { filterMap } from '@interacta-shared/util';
import { AIContextType } from '@modules/ai/models/ai.model';
import { AIOverlayService } from '@modules/ai/services/ai-overlay.service';

export const ANIMATION_DURATION_MS = 250;

@Component({
    selector: 'interacta-ai-fab',
    templateUrl: './ai-fab.component.html',
    styles: [
        `
            .gradient-border {
                border: double 2px transparent;
                background-origin: border-box;
                background-clip: content-box, border-box;
            }
            .gradient-border-thin {
                border: double 1px transparent;
                background-origin: border-box;
                background-clip: content-box, border-box;
            }
            .gradient-border-light {
                background-image: linear-gradient(
                        to top right,
                        var(--in-theme-surface-A),
                        var(--in-theme-surface-A)
                    ),
                    linear-gradient(
                        to top right,
                        var(--in-theme-surface-primary),
                        var(--in-theme-text-white)
                    );
            }

            .gradient-border-dark {
                background-image: linear-gradient(
                        to top right,
                        var(--in-theme-surface-B),
                        var(--in-theme-surface-300)
                    ),
                    linear-gradient(
                        to top right,
                        var(--in-theme-surface-primary),
                        var(--in-theme-text-white)
                    );
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIFabComponent {
    private readonly aiOverlayService = inject(AIOverlayService);

    @ViewChild('aiFabButton')
    aiFabButton?: ElementRef<HTMLButtonElement>;

    hovering = false;

    isOpen = signal<boolean>(false);
    showButton = signal<boolean>(true);

    ANIMATION_DURATION_MS = ANIMATION_DURATION_MS;
    readonly theme$: Observable<Theme>;

    private store = inject(Store);
    private destroyRef = inject(DestroyRef);

    constructor() {
        this.theme$ = this.store
            .select(selectCurrentTheme)
            .pipe(filterMap((theme) => theme ?? undefined));

        this.store
            .select(selectIsPanelOpen)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((value) => {
                    requestAnimationFrame(() => {
                        if (value) {
                            this.showButton.set(false);
                        }
                        this.isOpen.set(value);
                    });
                }),
                debounceTime(ANIMATION_DURATION_MS),
            )
            .subscribe((value) => {
                if (!value) {
                    this.showButton.set(true);
                }
            });

        this.aiOverlayService.close$
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.aiFabButton?.nativeElement.focus();
            });
    }

    toggleOpen(value: boolean): void {
        //https://injenia.atlassian.net/browse/IISP-9582
        //This dispatch was added as a result of the above issue,
        //since it is assumed that the FAB panel is only opened from the communities page.
        //When the FAB will be made (maybe) the default panel for the entire ai, this code will no longer be valid.
        //-----
        this.store.dispatch(
            AIActions.switchActiveContext({
                contextType: AIContextType.COMMUNITY,
            }),
        );
        //-----

        this.store.dispatch(AIActions.toggleAIPanel({ isOpen: value }));
    }
}
