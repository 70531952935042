<ng-container
    *ngIf="{
        postAtt: postAttachments$ | async,
        commentsAtt: commentsAttachments$ | async,
        customAtt: customFieldsAttachments$ | async,
        screenAtt: screenFieldsAttachments$ | async,
        selectedAttachment: selectedAttachment$ | async,
    } as $"
>
    <button
        #openMenu="cdkOverlayOrigin"
        cdkOverlayOrigin
        interacta-icon-button
        type="button"
        [attr.aria-label]="'AI.ADD_ATTACHMENT' | translate"
        [icon]="'paperclip'"
        [matTooltip]="'AI.ADD_ATTACHMENT' | translate"
        [size]="'small'"
        (click)="isOpen = !isOpen"
    ></button>
    <interacta-menu-dialog
        [open]="isOpen"
        [origin]="openMenu"
        [positionX]="'after'"
        (closing)="isOpen = false; selectAttachment(null, null)"
    >
        <div
            class="w-380 max-h-screen-80 gap-y-16"
            interactaMenuDecorator
            style="max-height: min(42rem, 80vh); min-height: min(32rem, 80vh)"
        >
            <section class="px-16 pt-16">
                <section class="flex items-center gap-x-8">
                    <interacta-image
                        alt=""
                        [classes]="'size-24'"
                        [src]="
                            theme === 'dark'
                                ? './assets/shared/images/ai/ai-interacta-logo_dark.svg'
                                : './assets/shared/images/ai/ai-interacta-logo_light.svg'
                        "
                    />
                    <h6 class="typo-t6">
                        {{ 'AI.ADD_ATTACHMENT' | translate }}
                    </h6>
                    <button
                        class="ml-auto"
                        interacta-icon-button
                        type="button"
                        [attr.aria-label]="'AI.CLOSE' | translate"
                        [icon]="'close'"
                        [matTooltip]="'AI.CLOSE' | translate"
                        [size]="'small'"
                        (click)="isOpen = false"
                    ></button>
                </section>
                <p class="typo-xs mt-8">
                    {{ 'AI.ADD_ATTACHMENT_DESCRIPTION' | translate }}
                </p>
            </section>
            <interacta-separator [thickness]="'soft'" />
            <section class="flex grow flex-col gap-y-12 overflow-y-auto px-16">
                @if (
                    ($.postAtt?.totalCount ?? 0) +
                        ($.commentsAtt?.totalCount ?? 0) +
                        ($.customAtt?.totalCount ?? 0) +
                        ($.screenAtt?.totalCount ?? 0) <=
                    0
                ) {
                    <p class="self-center">
                        {{ 'AI.NO_AVAILABLE_ATTACHMENTS' | translate }}
                    </p>
                    <interacta-image
                        class="self-center"
                        [alt]="'AI.NO_AVAILABLE_ATTACHMENTS' | translate"
                        [classes]="'object-contain shrink-0 h-308'"
                        [src]="'assets/shared/images/doodles/empty-page.png'"
                    />
                } @else {
                    @if ($.postAtt && $.postAtt.totalCount) {
                        <interacta-ai-panel-chat-attachment-list
                            [attachments]="$.postAtt"
                            [selectedAttachment]="
                                $.selectedAttachment &&
                                $.selectedAttachment.entityType === 'post'
                                    ? $.selectedAttachment.attachment.id
                                    : null
                            "
                            [theme]="theme"
                            [title]="'AI.ATTACHMENTS_IN_POST' | translate"
                            (loadMore)="
                                loadMore('post', $.postAtt.pageTokenInfo)
                            "
                            (selectAttachment)="
                                selectAttachment('post', $event)
                            "
                        />
                    }
                    @if ($.customAtt && $.customAtt.totalCount) {
                        <interacta-ai-panel-chat-attachment-list
                            [attachments]="$.customAtt"
                            [selectedAttachment]="
                                $.selectedAttachment &&
                                $.selectedAttachment.entityType ===
                                    'postFilePicker'
                                    ? $.selectedAttachment.attachment.id
                                    : null
                            "
                            [theme]="theme"
                            [title]="
                                'AI.ATTACHMENTS_IN_CUSTOM_FIELDS' | translate
                            "
                            (loadMore)="
                                loadMore(
                                    'postFilePicker',
                                    $.customAtt.pageTokenInfo
                                )
                            "
                            (selectAttachment)="
                                selectAttachment('postFilePicker', $event)
                            "
                        />
                    }
                    @if (
                        workflowEnabled && $.screenAtt && $.screenAtt.totalCount
                    ) {
                        <interacta-ai-panel-chat-attachment-list
                            [attachments]="$.screenAtt"
                            [selectedAttachment]="
                                $.selectedAttachment &&
                                $.selectedAttachment.entityType ===
                                    'screenFilePicker'
                                    ? $.selectedAttachment.attachment.id
                                    : null
                            "
                            [theme]="theme"
                            [title]="'AI.ATTACHMENTS_IN_WORKFLOW' | translate"
                            (loadMore)="
                                loadMore(
                                    'screenFilePicker',
                                    $.screenAtt.pageTokenInfo
                                )
                            "
                            (selectAttachment)="
                                selectAttachment('screenFilePicker', $event)
                            "
                        />
                    }
                    @if ($.commentsAtt && $.commentsAtt.totalCount) {
                        <interacta-ai-panel-chat-attachment-list
                            [attachments]="$.commentsAtt"
                            [selectedAttachment]="
                                $.selectedAttachment &&
                                $.selectedAttachment.entityType === 'comment'
                                    ? $.selectedAttachment.attachment.id
                                    : null
                            "
                            [theme]="theme"
                            [title]="'AI.ATTACHMENTS_IN_COMMENTS' | translate"
                            (loadMore)="
                                loadMore('comment', $.commentsAtt.pageTokenInfo)
                            "
                            (selectAttachment)="
                                selectAttachment('comment', $event)
                            "
                        />
                    }
                }
            </section>

            <section class="flex justify-end gap-x-16 px-16 pb-16">
                <button
                    interacta-button
                    type="submit"
                    [appearance]="'ghost'"
                    [label]="'AI.CANCEL' | translate"
                    [size]="'regular'"
                    (click)="isOpen = false; selectAttachment(null, null)"
                ></button>
                <button
                    interacta-button
                    type="button"
                    [disabled]="!$.selectedAttachment"
                    [label]="'AI.INSERT' | translate"
                    [size]="'regular'"
                    (click)="sendSelectedAttachmentToAI()"
                ></button>
            </section>
        </div>
    </interacta-menu-dialog>
</ng-container>
